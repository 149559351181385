import React from 'react';
import { Link } from 'gatsby';
import axios from 'axios';
import { withNamespaces } from 'react-i18next';
import isEmpty from 'lodash/isEmpty';
import merge from 'lodash/merge';
import reduce from 'lodash/reduce';
import camelCase from 'lodash/camelCase';
import Recaptcha from 'react-google-recaptcha';
import ConfirmText from './ConfirmText';
import s from './LoginForm.module.scss';
import GlobalError from './GlobalError';
import FiledError from './FieldError';
import Api from '../utils/api';
import backArrow from '../assets/img/round-chevron-right-24-px.svg';
import Cell from './Cell';
import Grid from './Grid';
import InputFiled from './InputFiled';
import BackendErrors from '../utils/backendErrors';
import PageHeaderTitle from './PageHeaderTitle';

class ForgotPasswordBody extends React.Component {
  constructor(props) {
    super(props);

    this.errorMessages = {
      generalError: props.t('core:general_error'),
      enterEmailOrUserName: props.t('page_forgot_password:enter_email_or_username'),
    };

    this.api = new Api({
      gatsbyUrl: props.gatsbyUrl,
      pythonUrl: props.pythonUrl,
      i18n: props.i18n,
    });
    this.backendErrors = new BackendErrors(this.api, props.i18n);

    this.state = {
      usernameOrEmail: {
        value: '',
        isValid: false,
        isTouched: false,
        errorMsg: [this.errorMessages.enterEmailOrUserName],
      },
      isFormValid: false,
      isFormSubmitted: false,
      isSubmitSuccess: false,
      errorMsg: [''],
    };

    this.onLoginInput = this.onLoginInput.bind(this);
    this.onSubmitForm = this.onSubmitForm.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.onSubmitClick = this.onSubmitClick.bind(this);
    this.onCaptchaExpired = this.onCaptchaExpired.bind(this);
  }

  validateForm() {
    const { usernameOrEmail } = this.state;

    const usernameState = {
      isTouched: true,
      isValid: isEmpty(usernameOrEmail.value),
    };

    this.setState(state => merge(state, usernameState));
  }

  onSubmitClick() {
    this.setState(state => merge(state, { usernameOrEmail: { isTouched: true } }));
  }

  onSubmitForm(e) {
    e.preventDefault();
    this.validateForm();

    const { usernameOrEmail } = this.state;

    if (!usernameOrEmail.isValid) {
      return;
    }

    if (this.recaptcha) {
      this.recaptcha.execute();
    } else {
      this.submitForm();
    }
  }

  onCaptchaExpired() {
    this.recaptcha.reset();
  }

  submitForm(token) {
    const { usernameOrEmail } = this.state;
    const axiosConfig = ({ data }) => this.api.forgotPasswordConfig({ data });

    const form = new FormData();

    form.append('reset_link', this.api.updatePasswordUrl());
    form.append('username_or_email', usernameOrEmail.value);

    if (token) {
      form.append('recaptcha_token', token);
    }

    this.setState({ isFormSubmitted: true });

    axios(axiosConfig({ data: form })).then(response => {
      if (response.data.status === 'ok') {
        this.setState(state => merge(state, {
          isSubmitSuccess: true,
          errorMsg: [''],
        }));
      }
    }).catch(error => {
      if (this.recaptcha) {
        this.recaptcha.reset();
      }

      if (!error.response) {
        this.setState(state => merge(state, {
          errorMsg: {
            general: [self.errorMessages.generalError],
          },
        }));
        return;
      }

      const { errors } = error.response.data;

      const self = this;
      if (errors.field_errors) {
        // show local filed errors
        const reduceFn = (acc, key) => merge(acc, {
          [camelCase(key)]: {
            errorMsg: self.backendErrors.array2messages(errors.field_errors[key]),
            isValid: false,
          },
        });

        const errorState = reduce(Object.keys(errors.field_errors), reduceFn, {});

        this.setState(state => merge(state, errorState));
      } else {
        this.setState({ errorMsg: error.response.data.errors });
      }
    });
  }

  onLoginInput(e) {
    const { value } = e.target;
    const usernameOrEmail = {};

    usernameOrEmail.isTouched = true;
    usernameOrEmail.value = value;
    usernameOrEmail.isValid = !isEmpty(value);

    this.setState(state => merge(state, { usernameOrEmail }));
  }

  render() {
    const { sitekey, recaptchaEnabled, brand, t } = this.props;
    const { usernameOrEmail, errorMsg, isSubmitSuccess, isFormSubmitted } = this.state;

    return (
      <Grid>
        <PageHeaderTitle brandName={brand.name} title={t('page_forgot_password:forgot_password')} />
        <Cell />
        <Cell style={{ padding: '0 24px' }} desktop={12} tablet={8} phone={4} align='middle'>
          <form onSubmit={this.onSubmitForm} data-test='fp-form'>
            {isSubmitSuccess
              ? (
                <div data-test='fp-confirm-message'>
                  <header className={s.header}>
                    {t('page_forgot_password:email_was_sent')}
                  </header>
                  {isFormSubmitted
                    ? <GlobalError data-test='fp-global-err' errors={errorMsg} />
                    : <div />
                  }
                  <ConfirmText text={t('page_forgot_password:email_just_sent')} />
                  <hr className={s.hr} />
                </div>
              ) : (
                <section data-test='fp-form-body'>
                  <header className={s.header}>
                    {t('page_forgot_password:forgot_your_password')}
                  </header>
                  {isFormSubmitted
                    ? <GlobalError data-test='fp-global-err' errors={errorMsg} />
                    : <div />
                  }
                  <div>
                    <div className={s.field}>
                      <label className={s.label} htmlFor='login'>
                        {t('page_forgot_password:username_or_email')}
                      </label>
                      <InputFiled
                        name='username_or_email'
                        id='username_or_email'
                        type='text'
                        hasError={usernameOrEmail.isTouched && !usernameOrEmail.isValid}
                        onInput={this.onLoginInput}
                        data-test='fp-input-username'
                      />
                      {usernameOrEmail.isTouched
                      && !usernameOrEmail.isValid
                      && <FiledError data-test='fp-field-err-username' errorMsg={usernameOrEmail.errorMsg} />}
                    </div>
                  </div>
                  <button
                    className={s.loginBtn}
                    type='submit'
                    color='primary'
                    onClick={this.onSubmitClick}
                    data-test='fp-submit'
                  >
                    {t('page_forgot_password:send_reset_link')}
                  </button>
                  <hr className={s.hr} />
                </section>
              )}
            <Link
              data-test='link-to-login'
              to={this.api.loginUrl()}
              className={`${s.noAccount} ${s.bottomLink}`}
            >
              <div className={s.backArrow}>
                <img src={backArrow} />
              </div>
              <span>
                {t('page_forgot_password:back_to_login')}
              </span>
            </Link>
            {recaptchaEnabled === 'true' && (
              <Recaptcha
                sitekey={sitekey}
                ref={e => this.recaptcha = e}
                size='invisible'
                onChange={this.submitForm}
                onExpired={this.onCaptchaExpired}
              />
            )}
          </form>
        </Cell>
        <Cell />
      </Grid>
    );
  }
}

export default withNamespaces()(ForgotPasswordBody);
