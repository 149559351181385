import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import get from 'lodash/get';

import Layout from '../layouts/index';
import LoginPageBody from '../components/LoginPageBody';
import ForgotPasswordBody from '../components/ForgotPasswordBody';

class ForgotPasswordPage extends React.Component {
  render() {
    const {
      invisibleRecaptchaSiteKey,
      recaptchaEnabled,
      gatsbyUrl,
      pythonUrl,
      brand,
      affiliateUrl
    } = get(this.props, 'data.site.siteMetadata');
    const { location } = this.props;

    return (
      <Layout location={location}>
        <LoginPageBody
          gatsbyUrl={gatsbyUrl}
          pythonUrl={pythonUrl}
          brand={brand}
        >
          <ForgotPasswordBody
            gatsbyUrl={gatsbyUrl}
            pythonUrl={pythonUrl}
            location={location}
            sitekey={invisibleRecaptchaSiteKey}
            recaptchaEnabled={recaptchaEnabled}
            brand={brand}
            affiliateUrl={affiliateUrl}
          />
        </LoginPageBody>
      </Layout>
    );
  }
}

const pageQuery = graphql`
  query ForgotPasswordPageQuery {
    site {
      siteMetadata {
        gatsbyUrl
        pythonUrl
        invisibleRecaptchaSiteKey
        recaptchaEnabled
        affiliateUrl
        brand {
          name
          nameCom
          twitter
          facebook
        }
      }
    }
  }
`;

export default ({ location }) => (
  <StaticQuery
    query={pageQuery}
    render={data => <ForgotPasswordPage data={data} location={location} />}
  />
);
