import isArray from 'lodash/isArray';

export default class {
  constructor(api, i18n) {
    this.api = api;
    this.i18n = i18n;

    const link = this.api.forgotPasswordUrlAbs();
    this.fields = {
      url: link,
    };
  }

  errors2messages(errors) {
    if (errors.general) {
      errors.general = this.array2messages(errors.general);
    }

    if (errors.field_errors) {
      for (const key in errors.field_errors) {
        errors.field_errors[key] = this.array2messages(
          errors.field_errors[key],
        );
      }
    }
  }

  array2messages(arr) {
    return isArray(arr)
      ? arr.map(msg => this.i18n.t(`backend:${msg}`, this.fields))
      : '';
  }
}
