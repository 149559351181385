import React from 'react';
import map from 'lodash/map';
import styled from 'styled-components';
import { withNamespaces } from 'react-i18next';

const GlobalErrorDiv = styled.div`
  width: 100%;
  padding: 12px 18px;
  margin-bottom: 24px;
  background-color: rgba(255, 51, 85, 0.08);
  border-radius: 4px;
  color: #cc2944;
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.3px;
  
  a {
    text-decoration: underline;
  }
`;

const GlobalError = ({ errors }) => (errors !== '' ? (
  <div>
    {map(Object.values(errors), (arr, i) => {
      if (Array.isArray(arr)) {
        return (
          <GlobalErrorDiv
            key={`${arr[0]}${i}`}
            dangerouslySetInnerHTML={{ __html: arr[0] }}
          />
        );
      }
      return '';
    })}
  </div>
) : (
  <div />
));

export default withNamespaces()(GlobalError);
