import React from 'react';
import { Helmet } from 'react-helmet';
import classnames from 'classnames';
import { withNamespaces } from 'react-i18next';
import Grid from './Grid';
import Cell from './Cell';
import Header from './Header';
import s from './LoginPageBody.module.scss';
import Api from '../utils/api';

class LoginPageBody extends React.Component {
  constructor(props) {
    super(props);
    this.api = new Api({
      gatsbyUrl: props.gatsbyUrl,
      pythonUrl: props.pythonUrl,
      i18n: props.i18n,
    });
  }

  render() {
    const { children, brand } = this.props;

    return (
      <div className={s.page}>
        <Helmet>
          <meta httpEquiv='pragma' content='no-cache' />
          <meta httpEquiv='expires' content='-1' />
        </Helmet>
        <Grid className={classnames(s.layout, 'mdc-layout-grid--fixed-column-width')}>
          <Cell tablet={1} desktop={3} />
          <Cell tablet={6} desktop={6}>
            <Header siteTitle={brand.name} api={this.api} />
          </Cell>
          <Cell tablet={1} desktop={3} />
        </Grid>
        <Grid className={classnames(s.layout, 'mdc-layout-grid--fixed-column-width')}>
          <Cell tablet={1} desktop={3} />
          <Cell className={classnames(s.form)} tablet={6} desktop={6}>
            {children}
          </Cell>
          <Cell tablet={1} desktop={3} />
        </Grid>
      </div>
    );
  }
}

export default withNamespaces()(LoginPageBody);
