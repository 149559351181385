import React from 'react';
import { withNamespaces } from 'react-i18next';
import styles from './Header.module.scss';
import logo from '../assets/img/anonine.svg';
import Link from './Link';

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.siteTitle = props.siteTitle;
    this.i18n = props.i18n;
    this.api = props.api;
  }

  render() {
    return (
      <div className={styles.header}>
        <Link to={this.api.indexUrl()} className={styles.header__img}>
          <img title={this.siteTitle} src={logo} />
        </Link>
      </div>
    );
  }
}

export default withNamespaces()(Header);
