import React from 'react';
import { withNamespaces } from 'react-i18next';

import VisibilitySvg from '../assets/img/baseline-visibility-24px.svg';
import VisibilityOffSvg from '../assets/img/baseline-visibility_off-24px.svg';

// TODO: withNamespaces module messages

const Visibility = ({ show, t }) => (
  <div>
    <img
      alt={show ? t('core:hide_password') : t('core:show_password')}
      src={show ? VisibilityOffSvg : VisibilitySvg}
    />
  </div>
);

export default withNamespaces()(Visibility);
