import React from 'react';
import styled from 'styled-components';

const Text = styled.div`
  padding-bottom: 74px;
  margin-bottom: 0;
  text-align: center;
  line-height: 1.56;
  color: #4e5166;
`;

const ConfirmText = ({ text }) => (
  <Text>
    {text}
  </Text>
);

export default ConfirmText;
