import React from 'react';
import styled from 'styled-components';

const FieldErrorDiv = styled.div`
  padding: 0;
  color: #d03a53;
  font-family: Roboto, sans-serif;
  font-size: 12px;
  font-stretch: normal;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0.2px;
  line-height: 16px;
`;

const FieldError = props => {
  const { errorMsg, ...restProps } = props;

  return (
    <FieldErrorDiv {...restProps}>
      {errorMsg.join(', ')}
    </FieldErrorDiv>
  );
};

export default FieldError;
